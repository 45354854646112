<template>
  <div id="quotedHistory">
    <van-nav-bar title="已报价订单" :border="false" @click-left="goHome" right-text="有疑问" left-text="返回首页" @click-right="goSubPage('quoted_question')">
    </van-nav-bar>
    <van-tabs v-model="activeName" style="margin-top: 1rem" swipeable v-if="pageReadyShow">
      <van-tab title="已报价" name="a">
        <span class="tip" v-if="alreadyQuoted.length == 0? true: false">你还没有报价记录哦</span>
        <span class="tip" v-else>仅显示近30天的已报价记录</span>
        <div class="historyWrap">
          <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
            <OrderListPublic v-for="(item,index) in alreadyQuoted" :listPublicObj="item" :key="index" :listState="state" toPageName="quoted_detail" />
          </van-pull-refresh>
        </div>
      </van-tab>
      <van-tab title="未报价已结束" name="b">
        <span class="tip" v-if="alreadyQuoted.length == 0? true: false">无未报价记录哦</span>
        <span class="tip" v-else>仅显示近3天的已推送但不可报价的记录</span>
        <div class="historyWrap">
          <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
            <OrderListPublic v-for="(item,index) in loseQuoted" :listPublicObj="item" :key="index" />
          </van-pull-refresh>
        </div>
      </van-tab>
    </van-tabs>
    <div v-else>
      <loading show :type="2" />
    </div>
  </div>

</template>
<script>
import { Toast } from "vant";
import TopBar from "@/components/home/TopBar.vue";
import OrderListPublic from "@/components/order/OrderListPublic.vue";
export default {
  data() {
    return {
      count: 0,
      isLoading: false,
      pageReadyShow: false,
      activeName: "a",
      alreadyQuoted: [],
      loseQuoted: [],
      state: "",
      quotedType: {
        0: "已报价",
        1: "已指派其他师傅",
      },
    };
  },
  components: {
    TopBar,
    OrderListPublic,
  },
  created() {
    this.getAlreadyList();
    this.getLoseList();
  },
  methods: {
    getAlreadyList() {
      this.$http.get(`/api/v2/worker/orders/quoted`).then((res) => {
        console.log(res);
        this.alreadyQuoted = res.data.result.data;
        this.pageReadyShow = true;
      });
    },
    getLoseList() {
      this.$http.get("/api/v2/worker/orders/expired").then((res) => {
        console.log("未报价已结束", res);
        this.loseQuoted = res.data.result.data;
        this.pageReadyShow = true;
      });
    },
    goQuestion() {
      this.$router.push("./quotedQuestion");
    },
    goHome() {
      this.$router.push("/");
    },
    onRefresh() {
      this.getAlreadyList();
      setTimeout(() => {
        Toast("刷新成功");
        this.isLoading = false;
      }, 1000);
    },
  },
};
</script>
<style lang="less">
#quotedHistory {
  .tip {
    font-size: 1.4rem;
    padding: 1rem 0;
    text-align: center;
    margin: 1rem 1rem 0 1rem;
    display: block;
    // background: #fff;
    box-sizing: border-box;
    border-radius: 5px;
  }
  .historyWrap {
    width: 100%;
    .historyList {
      margin: 1rem;
      box-sizing: border-box;
      border-radius: 5px;
      .historyItem {
        padding: 1rem 1.4rem;
        background: #fff;
        margin: 1rem 0;
        .itemTitle {
          margin-bottom: 0.5rem;
          padding: 1rem 0;
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-bottom: 1px solid #f2f4f7;
          .type {
            font-size: 1.8rem;
          }
          .date {
            font-size: 1.4rem;
            color: #aaaaab;
          }
        }
        ul {
          li {
            display: flex;
            padding: 0.6rem 0;
            .publicMenu {
              color: #a7a7a7;
              width: 22%;
              white-space: nowrap;
            }
            .publicDes {
              margin-left: 0.5rem;
              flex: 1;
            }
          }
          font-size: 1.5rem;
        }
      }
    }
  }
}
</style>>